html,
body {
  background-color: $background;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-semibold;
}

h1 {
  font-size: 1.875rem;
}

h2 {
  font-size: 1.625rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

.table-row-link:hover {
  background: $montuslightgrey;
  cursor: pointer;
}

.environment-alert-bar {
  background-color: #fc0;
  color: #000;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
}
