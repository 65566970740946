// Fonts
$font-regular: "ibmplexmono-regular";
$font-medium: "ibmplexmono-medium";
$font-semibold: "ibmplexmono-semibold";
$font-bold: "ibmplexmono-bold";

// Placeholders
%transition {
  transition: 250ms all ease;
}

// Navigation height
$nav-height: 4.7rem;
