#webhook-logs-accordion {
  .d-flex.justify-content-start {
    > div {
      flex-basis: 200px;
    }
  }

  .order-id {
    > * {
      display: inline;
    }
  }
}
