body {
  overflow-y: hidden;

  .main-container {
    height: 100%;

    .main-content {
      max-height: calc(100% - #{$nav-height});
      overflow-y: scroll;
    }
  }
}
