// scss-lint:disable SelectorFormat
.sort_link {
  // scss-lint:enable SelectorFormat
  &::after {
    color: $grey;
    margin-left: 5px;
  }

  &.asc {
    &::after {
      content: "\2193";
    }
  }

  &.desc {
    &::after {
      content: "\2191";
    }
  }
}
