$theme-colors: (
  "primary": $montusblack,
  "secondary": $montusgreen,
  "success": $montusgreen,
  "info": $montusyellow,
  "warning": $montuspink,
  "dark": $montusdarkblue,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$font-family-sans-serif: "ibmplexmono-regular";

@import "bootstrap";

form {
  .form-subtitle {
    font-family: $font-semibold;
    font-size: 0.9rem;
  }

  label {
    font-family: $font-medium;
    font-size: 0.7rem;
    letter-spacing: 0.0625rem;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
  }

  .form-control {
    border-radius: 0;
    box-sizing: border-box;
    font-size: 0.9rem;
    height: auto;
    padding: 0.75rem;
    position: relative;

    &:focus {
      z-index: 2;
    }

    &::placeholder {
      color: $grey;
    }
  }

  pre {
    &.form-control {
      background-color: $input-disabled-bg; // taken from bootstrap-4.3.1/assets/stylesheets/bootstrap/_forms.scss
      font-size: 0.7rem;
    }
  }
}

.table {
  thead th {
    border-bottom-width: 0.0625rem;
    border-top: 0;
    font-family: $font-medium;
    font-size: 0.75rem;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
  }

  tbody {
    td:first-child {
      font-family: $font-semibold;
      font-size: 0.875rem;
    }

    td {
      font-family: $font-medium;
      font-size: 0.75rem;
      padding: 1.5rem 0.65rem;
    }
  }
}

.btn {
  border-radius: 0;
  font-family: $font-medium;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

.btn-lg {
  font-size: 0.75rem;
  min-width: 200px;
}

.btn-sm {
  font-size: 0.6rem;
  min-width: 30px;
}

.row {
  height: 100%;
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
}

.tooltip-inner {
  max-width: 30em;
}

.breadcrumb {
  background-color: inherit;
  font-size: 0.75em;
  letter-spacing: 0.0625em;
  padding: 0;
}

.list-unstyled {
  margin: 0;
}

.card {
  .card-title {
    font-family: $font-medium;
    font-size: 0.75rem;
    letter-spacing: 0.0625rem;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .card-text {
    font-size: 1.1rem;

    &.text-lg {
      font-size: 2rem;
    }
  }
}
