.hidden-desktop {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.hidden-mobile {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
