$montusblack: #2b2b2b;
$montusdarkblue: #1e456a;
$montusblue: rgb(0, 153, 255);
$montusgreen: #4bbabb;
$montuspink: rgb(255, 128, 128);
$montusyellow: rgb(195, 162, 84);
$montuslightgrey: #e6e6e6;
$montushovergrey: #f7f7f7;
$montusbordergrey: #e8e8e8;
$montustransparentblack: rgba(0, 0, 0, 0.1);
$white: #fff;
$grey: #999;
$background: #f5f5f5;
