@use "sass:color";

.pagination {
  margin: 0.25rem;
  padding: 0.25rem;

  * {
    border-radius: 3px;
  }

  a {
    background: $white;
    border: 1px solid $grey;
    color: $montusblack;
    margin: 0.2rem;
    padding: 0.2rem 0.4rem;
    text-decoration: none;
    transition: 100ms ease;

    &:hover {
      background: color.adjust($white, $lightness: -25%);
    }
  }

  em.current {
    background-color: $montusblack;
    border: 1px solid $montusblack;
    color: $montuslightgrey;
    font-style: normal;
    font-weight: bold;
    margin: 0.2rem;
    padding: 0.2rem 0.4rem;
  }

  span.disabled {
    border: 1px solid $montuslightgrey;
    color: color.adjust($grey, $lightness: 25%);
    margin: 0.2rem;
    padding: 0.2rem 0.4rem;
  }
}

.pagination a:hover,
.pagination a:active {
  border: 1px solid $grey;
  color: $montusblack;
}
