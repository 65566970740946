@use "sass:color";

#top-menu {
  border-bottom: 1px solid $montusbordergrey;
  height: $nav-height;
  z-index: 99;

  button {
    text-transform: none;
  }

  .dropdown-menu {
    border: 1px solid $montuslightgrey;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 $montustransparentblack;
    font-size: 0.9375rem;
    left: initial;
    right: -0.2rem;

    .nav-link {
      padding-left: 1rem;

      &:active {
        background: $montuslightgrey;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  button {
    color: $montusblack;
    padding: 0;
  }

  .nav-link {
    color: $montusblack;
  }

  #navbar-supported-content {
    background-color: $white;
    left: 0;
    padding: inherit;
    position: absolute;
    right: 0;
    top: 100%;
  }
}

#left-menu {
  background: $white;
  border-right: 1px solid $montusbordergrey;
  height: 100%;

  .menu-title {
    color: $montusblack;
    font-family: $font-semibold;
    font-size: 0.7rem;
    letter-spacing: 0.05rem;
    opacity: 0.5;
    padding: 0.75rem 1.6rem;
    text-transform: uppercase;
  }

  .list-group-item {
    border: 0;
    border-left: 5px solid transparent;

    a {
      color: color.adjust($montusblack, $alpha: 0.5);
    }

    svg {
      fill: color.adjust($montusblack, $alpha: 0.5);
      flex-basis: 1.25rem;
      min-width: 1.25rem;

      &.filled {
        display: none;
      }
    }

    &.active {
      background: $montushovergrey;
      border-left: 5px solid $montusblack;
      color: inherit;

      a {
        color: $montusblack;
      }

      svg {
        display: none;
        fill: $montusblack;

        &.filled {
          display: block;
        }
      }
    }

    &:hover {
      background: color.adjust($montushovergrey, $lightness: -2%);
    }
  }
}
