@import "globals/all";
@import "partials/all";
@import "features/all";

@include media-breakpoint-down(md) {
  body {
    .col-10 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

* {
  margin: 0;
  padding: 0;
}
