@font-face {
  font-family: "ibmplexmono-regular";
  font-weight: 400;
  src: url("../fonts/IBMPlexMono-Regular.ttf");
}

@font-face {
  font-family: "ibmplexmono-medium";
  font-weight: 500;
  src: url("../fonts/IBMPlexMono-Medium.ttf");
}

@font-face {
  font-family: "ibmplexmono-semibold";
  font-weight: 600;
  src: url("../fonts/IBMPlexMono-SemiBold.ttf");
}

@font-face {
  font-family: "ibmplexmono-bold";
  font-weight: 700;
  src: url("../fonts/IBMPlexMono-Bold.ttf");
}
